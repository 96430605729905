<template>
  <div class="w-full">
    <multiselect
      :options="acts"
      :multiple="true"
      :close-on-select="false"
      :clear-on-select="false"
      :preserve-search="false"
      :searchable="true"
      :preselect-first="true"
      v-model="selectedActs"
      track-by="_id"
      label="name"
      placeholder="Sélectionnez les actes"
      :class="custemClass ? custemClass : ''"
    >
      <template slot="noResult"
        >Il n'y a aucun résultat pour cette spécialité</template
      >
      <template slot="noOptions"
        >Il n'y a aucun résultat pour cette spécialité</template
      >
    </multiselect>
  </div>
</template>

<script>
export default {
  props: {
    selectedItems: {
      type: Array,
      required: true,
    },
    custemClass: {
      type: String,
      required: false,
    },
    selectedActsItems: {
      type: Array,
      required: true,
    },
    acts: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      selectedActs: [],
    };
  },
  created() {
    this.selectedActs = this.selectedActsItems;
  },

  watch: {
    selectedActs(newVal, oldVal) {
      this.$emit("update:selected-acts-items", this.selectedActs);
    },
  },

  methods: {},
};
</script>
